@font-face {
  font-family: "avenir-roman";
  src: url("../../fonts/Avenir-Roman.woff2") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "avenir-med";
  src: url("../../fonts/Avenir-Medium.woff2") format("truetype");
  font-style: normal;
  font-weight: normal;
}

.image-container {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1366px) {
  .image-app-image {
    display: block;
    width: 100vw;
  }
}
