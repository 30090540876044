@import "Variables";
@import "Reset";

* {
  box-sizing: border-box;
}

img {
  display: block;
}

button {
  cursor: pointer;
}

button, input, select {
  &:focus {
    outline: none;
  }
}

.container {
  padding: 0 16px;
  max-width: 100%;
  min-height: calc(100vh - 50px);
  overflow-y: hidden;
  &-block {
    padding: 0 16px;
    max-width: 100%;
  }
}

.lock {
  overflow-y: hidden;
}

:root {
  --primary-100: #E8E8FC;
  --primary-200: #D2D1F9;
  --primary-300: #BBBAF6;
  --primary-400: #A5A3F3;
  --primary: #8E8CF0;
  --primary-rgb: 142, 140, 240;
  --primary-600: #7270C0;
  --primary-700: #555490;
  --primary-800: #393860;
  --primary-900: #1C1C30;

  --secondary-100: #B1B1CE;
  --secondary-200: #8A8AB5;
  --secondary-300: #63639D;
  --secondary-400: #505090;
  --secondary: #3C3C84;
  --secondary-600: #282861;
  --secondary-700: #24244F;
  --secondary-800: #141333;
  --secondary-900: #0C0C1A;

  --tertiary-100: #F1E1F7;
  --tertiary-200: #E4C4F0;
  --tertiary-300: #D6A6E8;
  --tertiary-400: #C989E1;
  --tertiary: #BB6BD9;
  --tertiary-600: #9656AE;
  --tertiary-700: #704082;
  --tertiary-800: #4B2B57;
  --tertiary-900: #25152B;

  --success-100: #D3EADD;
  --success-200: #A6D5BA;
  --success-300: #7AC098;
  --success-400: #4DAB75;
  --success: #219653;
  --success-600: #1A7842;
  --success-700: #145A32;
  --success-800: #0D3C21;
  --success-900: #071E11;

  --error-100: #FACFD0;
  --error-200: #F49EA1;
  --error-300: #EF6E73;
  --error-400: #E93D44;
  --error: #E40D15;
  --error-600: #B60A11;
  --error-700: #89080D;
  --error-800: #5B0508;
  --error-900: #2E0304;

  --warning-100: #FCEBDB;
  --warning-200: #FAD6B7;
  --warning-300: #F7C292;
  --warning-400: #F5AD6E;
  --warning: #F2994A;
  --warning-600: #C27A3B;
  --warning-700: #915C2C;
  --warning-800: #613D1E;
  --warning-900: #301F0F;

  --typography-100: #FAFAFA;
  --typography-200: #E6E6E6;
  --typography-300: #CDCDCD;
  --typography-400: #9B9B9B;
  --typography: #828282;
  --typography-600: #686868;
  --typography-700: #4E4E4E;
  --typography-800: #343434;
  --typography-900: #1A1A1A;

  --star-100: #FAE9B7;
  --star-300: #F6D982;
  --star: #F2C94C;
  --star-700: #A98D35;
  --star-900: #61501E;

  --chart-300: #89DBF6;
  --chart: #56CCF2;
  --chart-700: #3C8FA9;
  --chart-900: #225261;

  --white-with-opacity-5: rgba(255, 255, 255, 0.05);
  --white-with-opacity-15: rgba(255, 255, 255, 0.15);
  --white-with-opacity-25: rgba(255, 255, 255, 0.25);
  --white-with-opacity-50: rgba(255, 255, 255, 0.5);
  --star-with-opacity-70: rgba(242, 201, 76, 0.7);


  --gradient-pink-right-part: #F2994A;
  --gradient-pink-left-part: #6A4DBC;
  --gradient-pink-base: linear-gradient(90.6deg, #6A4DBC 0.47%, #F2994A 137.94%);

  --gradient-tranquil: linear-gradient(83.5deg, #5E6FFD -15.87%, #AA8ACA 96.3%);

  --gradient-amethyst: linear-gradient(180deg, #141333 0%, #202261 70.63%, #3A237A 100%);
}
